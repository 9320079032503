/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import Typography from '../components/atom/typography';

export const renderContent = (content, theme) => {
  return content.split('\n').map((line, index) => {
    if (line.match(/^\d+\./)) {
      return (
        <Typography
          key={index}
          variant="body1"
          style={{
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(1),
          }}
        >
          {line}
        </Typography>
      );
    }
    if (line.startsWith('Note:')) {
      return (
        <Typography
          key={index}
          variant="body2"
          color="textSecondary"
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            fontStyle: 'italic',
          }}
        >
          {line}
        </Typography>
      );
    }
    return line.trim() ? (
      <Typography
        key={index}
        variant="body1"
        style={{ marginBottom: theme.spacing(2) }}
      >
        {line}
      </Typography>
    ) : null;
  });
};
