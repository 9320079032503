import React from 'react';
import * as PropTypes from 'prop-types';

import Typography from '../../atom/typography';
import makeStyles from '../../makeStyles';
import PageHelp from '~/shared/help/page-help';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const PageTitle = ({ children, icon, ...rest }) => {
  const classes = useStyles();
  return (
    <PageHelp
      fallback={
        <Typography variant="h1" className={classes.root} {...rest}>
          {children} {icon}
        </Typography>
      }
    />
  );
};

PageTitle.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.node,
};

PageTitle.defaultProps = {
  icon: undefined,
  children: undefined,
};

export default PageTitle;
