/* eslint-disable import/prefer-default-export */
import api from '../../util/api';

export const getPageHelp = path =>
  api.get(`/support/help/${encodeURIComponent(path)}`);
export const searchHelp = async queryText =>
  (await api.post(`/support/help/search`, { queryText })).payload;

export const getHelpPagesAdmin = () => api.get(`/support/admin/help`);
export const getHelpPageAdmin = path =>
  api.get(`/support/admin/help/${encodeURIComponent(path)}`);
export const createHelpPageAdmin = payload =>
  api.post(`/support/admin/help`, payload);
export const updateHelpPageAdmin = (path, payload) =>
  api.put(`/support/admin/help/${encodeURIComponent(path)}`, payload);
